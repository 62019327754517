<template>
  <b-card-code>
<!--    <loader/>-->
    <div class="mb-2" v-if="institute.image">
      <img :src="imgShow(institute.image)"  alt="logo" class="img-fluid"/>
    </div>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form ref="institute_form">
        <b-row>
          <b-col md="6">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Title')}}</span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="title"
                  rules="required"
              >
                <b-form-input
                    v-model="institute.title"
                    name="title"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Write title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Principal Photo')}}</span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="image"
                  :rules="institute.id?'':'required'"
              >
                <b-form-file
                    v-model="image"
                    name="image"
                    :state="errors.length > 0 ? false:null"
                    placeholder="select an image"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Description')}}</span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="description"
                  :rules="'required'"
              >
                <vue-editor v-model="institute.description"></vue-editor>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- submit button -->
          <b-col md="4">
            <b-button
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
            >
              Update
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
//import Loader from "@/views/Loader";
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BFormGroup, BFormInput,BFormFile,
  BForm,BRow, BCol,BCardText
} from 'bootstrap-vue'
import {required} from '@validations'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import { VueEditor } from "vue2-editor";
export default {
  name:'PrincipalMessage',
  components: {
    BCardCode,
    BFormGroup,
    BFormInput,BCardText,
    BButton,BFormFile,
    BForm,BRow, BCol,
    ValidationProvider,
    ValidationObserver,//Loader
    VueEditor
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      institute:{},
      image:null,
      required,
      pageLength: 5,
      dir: false,
    }
  },
  methods:{
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData(this.$refs.institute_form);
          data.append('description',this.institute.description);
          apiCall.post('/principal/message/update/store',data).then((response)=>{
            this.$toaster.success(response.data.message);
            this.image=null;
            this.$refs.simpleRules.reset();
          }).catch((error)=>{
            if (error.response.status ==422) {
              Object.keys(error.response.data.errors).map((field) => {
                this.$toaster.error(error.response.data.errors[field][0]);
              })
            }
            else this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    imgShow(path) {
      return process.env.VUE_APP_ENV_RESOURCHURL + path;
    },
    async getMessage(){
      await apiCall.get('/principal/message').then((response)=>{
        this.institute=response.data;
      }).catch(()=>{
        this.institute={};
      })
    }
  },
  created() {
    this.getMessage();
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>